@import "../node_modules/antd/dist/antd.css";

.total-text {
  font-weight: bold;
}

.currency-td {
  text-decoration: underline;
  text-decoration-style: solid;
  cursor: pointer;
}
.total-align {
  text-align: right;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overlay-bg:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

.relative {
  position: relative;
}

::-webkit-input-placeholder {
  color: #666;
}

::-moz-placeholder {
  color: #666;
}

::-ms-input-placeholder {
  color: #666;
}

::placeholder {
  color: #666;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}

body {
  font-weight: normal !important;
  margin: 0;
  font-size: 16px;
  line-height: 1;
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  font-family: Roboto, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

li,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

td {
  position: relative;
  font-weight: normal;
  -ms-font-feature-settings: "tnum" 1;
  -o-font-feature-settings: "tnum" 1;
  -webkit-font-feature-settings: "tnum" 1;
  font-feature-settings: "tnum" 1;
}

.btn_filter {
  height: 35.5px;
  min-width: 95px;
  font-size: 14px;
}

.card {
  overflow: hidden;
}
.card .ant-form-item-control {
  line-height: 35.5px;
}
.card .ant-form-explain,
.card .ant-form-extra {
  font-size: 13px;
}

.card input,
.card label,
.card select {
  font-size: 14px;
}

.card input:not([type="checkbox"]),
.card select,
.card .ant-select-selection {
  height: 35.5px !important;
}

.card td {
  padding: 0.75rem !important;
  line-height: 1;
  color: #4c4c4c;
  font-size: 15px;
  font-weight: normal;
}
.card th {
  font-size: 15px;
  color: rgba(73, 80, 87, 0.5) !important;
  background-color: #fff !important;
  padding: 0.75rem !important;
  line-height: 1;
  font-weight: normal;
}
@media (max-width: 991px) {
  .card td,
  .card th {
    padding: 0.625rem 0.5rem !important;
  }
}
@media (max-width: 991px) {
  .card td,
  .card th {
    font-size: 13px;
  }
}

.card-extra {
  background-color: rgba(73, 80, 87, 0.05);
}

.ant-table-column-title {
  font-weight: 700;
}
th {
  background-color: #e9ecef;
}

.ant-select {
  width: 100%;
}

.ant-select-selection__rendered {
  line-height: 34px;
}
.ant-table-pagination {
  margin-right: 20px !important;
}
.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  line-height: 29px;
}
.ant-table-column-title {
  font-weight: normal;
}

.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td {
  background: #fbfbfb;
}
.antd-table-col-currency small {
  color: rgba(40, 44, 48, 0.5);
}
.ant-table-body {
  overflow-x: auto !important;
}
.anticon {
  vertical-align: initial;
}

.add-client__label {
  display: block;
  text-align: right;
  margin-bottom: 0;
  line-height: 35.5px;
}

.ant-form-item-label > label::after {
  top: 0;
}

.add-client__btn {
  min-width: 100px;
}

.add-client__btn:not(:last-child) {
  margin-right: 15px;
}

.login-img-wrap {
  width: 200px;
  height: 99px;
}
.ant-modal-mask {
  z-index: 1071;
}
.ant-modal-wrap {
  z-index: 1072;
}

.ant-select-dropdown {
  min-width: 150px !important;
}
@media (max-width: 767px) {
  .ant-select-dropdown {
    min-width: 0 !important;
  }
}

input.ant-input,
.ant-input-number {
  height: 35.5px !important;
}

.icon {
  cursor: pointer;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none !important;
}
.ant-table-thead > tr > th:first-child,
.ant-table-tbody > tr > td:first-child {
  padding-left: 20px !important;
}
.ant-table-thead > tr > th:last-child,
.ant-table-tbody > tr > td:last-child {
  padding-right: 20px !important;
}
.ant-table-thead > tr > th {
  background-color: transparent !important;
}

.ant-btn {
  height: 35.5px !important;
}

.ant-btn.ant-btn-success {
  color: #fff;
  border-color: #17c671;
  background-color: #17c671;
}
.version-span {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.7;
  font-size: 16px;
}
.ant-btn.ant-btn-success:hover {
  color: #fff;
  background-color: #14af64;
  border-color: #14af64;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(23, 198, 113, 0.25);
}
.ant-btn.ant-btn-success:focus {
  box-shadow: 0 0 0 3px rgba(23, 198, 113, 0.15),
    0 3px 15px rgba(23, 198, 113, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #fff;
  border-color: #17c671;
  background-color: #17c671;
}
.ant-btn.ant-btn-secondary {
  color: #fff;
  border-color: #5a6169;
  background-color: #5a6169;
  box-shadow: none;
}
.ant-btn.ant-btn-secondary:hover {
  color: #fff;
  background-color: #4e545b;
  border-color: #4e545b;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(90, 97, 105, 0.25);
}
.ant-btn.ant-btn-secondary:focus {
  color: #fff;
  background-color: #4e545b;
  border-color: #4e545b;
  box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
    0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}
.login-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-img-wrap {
  margin-bottom: 35px;
}
.btn-login {
  padding: 5px 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.btn-login-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: transparent;
}
.nowrap-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 285px;
  line-height: 1.14;
  overflow: hidden;
}
.anticon.ant-calendar-picker-clear,
.anticon.ant-calendar-picker-icon {
  display: flex !important;
  opacity: 1 !important;
  margin-top: -6px !important;
}
@media (max-width: 1200px) {
  .nowrap-text {
    line-height: 1.3;
  }
}
.ant-form-item-label > label:after {
  margin-left: 0 !important;
}
.option-btn {
  position: absolute;
  top: calc(50% - 15px);
  left: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='18px' height='18px' viewBox='0 0 408 408' style='enable-background:new 0 0 408 408;' xml:space='preserve'%3E%3Cpath d='M51,153c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S79.05,153,51,153z M357,153c-28.05,0-51,22.95-51,51 s22.95,51,51,51s51-22.95,51-51S385.05,153,357,153z M204,153c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51 S232.05,153,204,153z' fill='%233d5170'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.nav-wrapper-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.nav-wrapper-flex .nav {
  width: 100%;
}
.logout-btn {
  border-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  margin-left: 25px;
  margin-bottom: 20px;
}
.logout-btn:hover span {
  text-decoration: underline;
}
.logout-btn .anticon {
  line-height: 0 !important;
}
.logout-btn span,
.logout-btn .anticon {
  vertical-align: middle;
}
.view-client-link {
  text-decoration: none !important;
}

@media screen and (max-width: 991px) {
  .logout-btn {
    height: 25px;
    margin-left: 10px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
  }
  .logout-btn .anticon {
    line-height: 13px;
  }
}
@media screen and (max-width: 575px) {
  .logout-btn .anticon {
    line-height: 24px;
    display: flex;
  }
  .logout-btn__text {
    display: none !important;
  }
}
@media screen and (min-width: 1330px) {
  .form-col {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
}
@media screen and (min-width: 1700px) {
  .form-col {
    flex: 0 0 58.333333% !important;
    max-width: 58.333333% !important;
  }
}
@media (max-width: 991px) {
  .main-sidebar {
    transform: translateX(-100%);
    box-shadow: none;
  }
}
table.ant-table-fixed > tbody > tr {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table table {
  border-collapse: collapse;
}

.ant-table-tbody > tr > td {
  border: none !important;
}

.dynamic-delete-button {
  font-size: 18px;
  color: #5a6169;
  transition: all 0.3s;
  cursor: pointer;
}
.dynamic-delete-button:hover {
  color: #40a9ff;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.card-extra {
  padding: 20px !important;
  background-color: rgba(73, 80, 87, 0.05) !important ;
}
.main-content-container {
  padding: 0 24px !important;
}
.page-header {
  padding: 24px 0;
  align-items: center;
}
@media (max-width: 991px) {
  .card input,
  .card .ant-input,
  .card label,
  .card select,
  .card .ant-select {
    font-size: 13px;
  }
  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    right: 7px !important;
  }
}
@media (max-width: 767px) {
  .page-header .page-title {
    font-size: 1.625rem !important;
  }
  .page-header {
    padding: 15px 0;
  }
  .ant-btn {
    padding: 0 10px;
    font-size: 13px;
  }
  .main-content-container {
    padding: 0 15px !important;
  }
  .ant-message {
    z-index: 2000;
  }
}
@media (max-width: 1200px) {
  .main-sidebar .nav .nav-item .nav-link {
    padding: 0.725rem 0.725rem !important;
  }
}
@media (max-width: 767px) {
  .ant-pagination {
    font-size: 12px;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 27px;
    height: 27px;
  }
  .ant-table-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 575px) {
  .ant-form-item-label {
    padding: 0;
  }
}
.ant-table-tbody > tr > td,
.ant-table-column-title {
  font-weight: normal;
}
.main-content {
  padding: 0 0 40px 0 !important;
}
.date-expired {
  color: red !important;
}