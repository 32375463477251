.ant-upload-list {
  max-width: 300px;
}
.upload-btn {
  display: block;
  margin-right: 10px;
}
.upload-delete-btn {
  padding: 0 10px !important;
}
.upload-delete-btn:focus,
.upload-delete-btn:hover {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.upload-list {
  max-width: 300px;
}
.upload-list .ant-list-empty-text {
  display: none;
}
.upload-list__item {
  padding: 0 !important;
  margin-bottom: 5px;
}
.upload-list__item:last-child {
  margin-bottom: 0;
}
.upload-btn-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.ant-upload-list-item-info {
  padding-right: 20px;
}
