.primary-persons .dynamic-delete-button {
  position: absolute;
  top: 31px;
  right: -30px;
}
.primary-persons {
  margin: 0 auto 15px;
  padding: 10px 0 12px;
}
.primary-wrap {
  position: relative;
  margin-bottom: 14px;
}

.primary-inputs {
  display: flex;
}
.primary-input {
  flex-shrink: 0;
}
.primary-input:first-child {
  width: calc((100% - 115px) / 2 + 115px);
}
.primary-input:last-child {
  width: calc((100% - 115px) / 2);
}
.primary-input:first-child .ant-form-item-control-wrapper {
  width: calc(100% - 115px);
}
.primary-input:last-child .ant-form-item-control-wrapper {
  width: 99%;
}

@media (max-width: 575px) {
  .primary-inputs {
    flex-direction: column;
  }
  .primary-input:first-child,
  .primary-input:last-child,
  .primary-input:first-child .ant-form-item-control-wrapper,
  .primary-input:last-child .ant-form-item-control-wrapper {
    width: 100%;
  }
  .primary-persons .dynamic-delete-button {
    right: initial;
    top: 10px;
    left: 110px;
  }
}
