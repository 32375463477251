.invite-form .ant-form-item {
  margin-bottom: 16px;
}
.invite-form label {
  margin: 0;
}
.invite-form .ant-form-item-label {
  line-height: 35.5px;
  text-align: left;
}
.invite-form .ant-form-item:last-child {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .invite-form__btn {
    text-align: center;
  }
}
