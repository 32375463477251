.client-form > .ant-form-item {
  margin: 0 auto 8px;
  width: 100%;
  max-width: 680px;
}
.client-form .ant-form-item {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
  justify-content: flex-end;
}
.client-form > .ant-form-item:last-child,
.client-form .ant-form-item:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 575px) {
  .client-form .ant-form-item {
    flex-direction: column;
  }
  .client-form .ant-form-item:last-child {
    text-align: center;
  }
  .client-form-col {
    max-width: 400px;
    margin: 0 auto;
  }
}
.client-form .form-item-group {
  margin: 0 auto 8px;
  width: 100%;
  max-width: 680px;
}
.client-form .ant-form-item:not(.primary-input) .ant-form-item-control-wrapper {
  width: 83%;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .client-form .ant-form-item:not(.primary-input) .ant-form-item-control-wrapper {
    width: 77.4%;
  }
}
@media (max-width: 575px) {
  .client-form .ant-form-item:not(.primary-input) .ant-form-item-control-wrapper {
    width: 100%;
  }
}
.client-form label {
  margin-bottom: 0 !important;
  width: 116px;
}
.client-form .ant-form-item-label {
  line-height: 35.5px;
}
.client-form .ant-form-item-control {
  line-height: 1;
}
.client-form textarea {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .client-form {
    margin: 0 auto;
    max-width: 514px;
  }
}
@media screen and (max-width: 575px) {
  .client-form .ant-form-item-label {
    text-align: left;
  }
}

/* State and Zip Code divided inputs */

.address-inputs {
  display: flex;
  justify-content: flex-end;
}
.address-inputs > .ant-form-item {
  margin-bottom: 0;
}
.address-inputs > .ant-form-item:first-child {
  width: calc((100% - 115px) * 0.7 - 4px);
  margin-right: 3px;
}
.address-inputs > .ant-form-item:last-child {
  width: calc((100% - 115px) * 0.3);
}
.address-inputs .ant-form-item-control-wrapper {
  width: 100% !important;
}
@media screen and (max-width: 575px) {
  .address-inputs {
    flex-direction: column;
  }
  .address-inputs > .ant-form-item:first-child {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .address-inputs > .ant-form-item:last-child {
    width: 100%;
  }
}

/* Details input group */

@media screen and (max-width: 575px) {
  .input-group-details .ant-select {
    /* margin-right: 0 !important; */
  }
  .input-group-details .ant-select:nth-child(1) {
    width: 70% !important;
  }
  .input-group-details .ant-select:nth-child(2) {
    width: 30% !important;
  }
  .input-group-details .ant-select:nth-child(2) .ant-select-selection {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .input-group-details .ant-select:nth-child(3) {
    width: 100% !important;
    margin-top: 8px;
  }
  .input-group-details .ant-select:nth-child(3) .ant-select-selection {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
