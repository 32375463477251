.invoice-form .ant-form-item-label {
  line-height: 2;
}
.invoice-form .ant-form-item-label label {
  margin-bottom: 0;
}
.invoice-form .ant-form-item {
  margin-bottom: 4px;
}
.m-0 .card-info-row .last-row {
  display: block;
}

.payments {
  padding: 10px 20px;
}
.form__remove-row {
  position: absolute;
  right: -19px;
  top: 8px;
  display: inline-block;
  margin-left: auto;
  padding: 0 12px;
  border: 0;
  background: 0;
  line-height: 1;
  vertical-align: middle;
  transition: color 0.25s;
}
.invoice-info__item {
  flex-wrap: wrap;
}
.ant-col.ant-form-item-control-wrapper {
  width: 100%;
}
.form__remove-row:hover,
.form__remove-row:focus {
  color: #1890ff;
  outline: 0;
}
.form__remove-row > i.material-icons {
  font-size: 1.125rem;
}
.form__control-row {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.form__top {
  padding: 10px 20px;
  border-bottom: 1px solid #d9d9d9;
  min-height: 86px;
}
.ant-upload-list {
  max-width: 300px;
}

@media (max-width: 1300px) {
  .invoice-form-col {
    width: 100% !important;
  }
}
@media (max-width: 1200px) {
  .invoice-form-col .card input,
  .invoice-form-col .card .ant-input,
  .invoice-form-col .card label,
  .invoice-form-col .card select,
  .invoice-form-col .card .ant-select {
    font-size: 13px;
  }
  .invoice-form .ant-calendar-picker .anticon {
    right: 6px;
  }
}
@media (max-width: 850px) {
  .form__top {
    display: flex;
    flex-wrap: wrap;
  }
  .form__top .ant-col {
    float: none;
  }
}
@media (max-width: 991px) {
  .ant-upload-list-item .anticon-close {
    opacity: 1;
  }
}
@media (max-width: 575px) {
  .payments-inner {
    overflow-x: scroll;
    padding-bottom: 10px;
  }
  .payment-wrap {
    min-width: 515px;
  }
}
