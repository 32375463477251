.total {
  text-align: right;
}

.total__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.total__row:last-child {
  border-top: 1px solid #e1e5eb;
  padding-top: 7px;
  margin-bottom: 15px;
}

.total__text {
  font-weight: normal;
}

.total__text_lg,
.total__text_lg span {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.total-btn-wrap {
  display: block;
}
.total-btn-wrap button:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .total-col {
    margin-left: auto;
  }
}
@media (max-width: 575px) {
  .total-btn-wrap {
    justify-content: center;
  }
}
