.staff-form .ant-form-item {
  margin-bottom: 16px;
  display: flex;
}
@media (max-width: 575px) {
  .staff-form .ant-form-item {
    display: block;
  }
}
.staff-form .ant-form-item:first-child {
  margin-bottom: 11px;
}
.staff-form .ant-form-item:last-child {
  margin-bottom: 0;
}
.staff-form .ant-form-item-label {
  line-height: 1.3;
}
.staff-form .ant-form-item:nth-last-child(2) .ant-form-item-label {
  line-height: 35.5px;
}
.staff-form .ant-form-item-control {
  line-height: 1.3;
}
.staff-form .ant-form-item-control-wrapper {
  width: 75%;
}
@media (max-width: 575px) {
  .staff-form .ant-form-item-control-wrapper {
    width: 100%;
  }
}
.staff-form label {
  margin-bottom: 0;
}
.staff-item {
  color: rgba(0, 0, 0, 0.65) !important;
}
