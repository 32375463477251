.invoice-info {
  float: none;
}
.invoice-info__item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  min-width: 515px;
}
.invoice-info__item .ant-form-item-control-wrapper {
  width: 400px;
}
.invoice-info__item .ant-col.ant-form-item-control-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .invoice-info__item {
    width: 100%;
    justify-content: center;
  }
  .invoice-info__item .ant-form-item-control-wrapper {
    flex-grow: 1;
    width: auto;
  }
  .invoice-info__item .ant-form-item-label {
    width: auto;
    flex-shrink: 0;
    margin-right: 15px;
  }
}
