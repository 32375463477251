.invoice-filter {
  padding: 10px 20px;
}
.invoice-filter .ant-calendar-picker {
  width: 100%;
}
.btn_clean {
  text-decoration: none;
  line-height: 35.5px;
  margin-left: 15px;
  border-bottom: 1px dashed;
  cursor: pointer;
  margin-bottom: 10px;
}
@media screen and (max-width: 1599px) {
  .invoice-filter .ant-col {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 575px) {
  .invoice-filter {
    justify-content: center;
  }
  .invoice-filter .ant-col label {
    margin-bottom: 0.1rem !important;
  }
  .invoice-filter .btn_filter {
    min-width: 120px;
  }
}
