.main-navbar .flex-column {
  flex-direction: row !important;
}
.main-navbar .main-navbar__list {
  display: flex;
}
.main-navbar_mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
@media screen and (min-width: 992px) {
  .main-navbar_mobile {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .main-navbar .nav-link {
    padding: 3px 5px;
  }
  .main-navbar__wrapper {
    align-items: center;
    flex-direction: row !important;
  }
  .main-navbar__wrapper .item-icon-wrapper {
    display: none !important;
    font-weight: normal;
  }
}
@media screen and (max-width: 767px) {
  .main-navbar_mobile {
    padding: 0 15px;
  }
}
